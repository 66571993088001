import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import PageCom2 from "../../Components/Pagecom2/PageCom2";


import Strcomp from"../../Components/Servicecomp/Strcomp";

export default function St() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <PageCom2 name="SOFTWARE TRAINING"/>
     <div className="ctcmp1" >
      <Zoom>
        <Strcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
