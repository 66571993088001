import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";
import Hncomp from"../../Components/Servicecomp/Hncomp";

export default function Hardwarenet() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="HARDWARE AND NETWORKING"/>
     <div className="ctcmp1" >
      <Zoom>
        <Hncomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
