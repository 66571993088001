import React from "react";

import img10 from "../../Assets/Images/img73.jpg";

import Fade from "react-reveal/Fade";

export default function Hrmcomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">
          <b>LITVIK HRMS</b> offers instant centralized access and control to
           all key Human Resources data from anywhere to provide faster,
            more accurate decision making. With the HRMS you can save time
             and money by eliminating excessive manual entry and labor-intensive processes.<br/><br/> 

          We offer a suite of reports and interfaces as standard. But we 
          also give you the ability to extend its functionality as your 
          business grows, without needing to change o purchase any new software.<br/><br/> 

          The HRMS is a single solution that combines all the functionalities
           of Human Resource Management and Labor Management. It provides integration 
           between multiple functions of a business and enables the organization
            to manage its employees' productivity and performance thus optimizing 
            its costs and increasing its competitive edge. <br/><br/>
          </div>
          
        
          <div className="act12">
            
            <img className="d-block w-200" height="300" src={img10} />
            </div>
          
        
      
          <div className="act24">
              <table>
              <tr>
                <td >
                  
                  <ul>
                    <li>Recruitment process</li>
                    <li>Employees & Workers Data</li>
                    <li>Attendance & Leave Information</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Salaries & Wages Properties</li>
                    <li>Integration of all Department</li>
                    <li>Advances,Conveyance Information</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          
        </Fade>
        
      </div>
    </div>
    
  );
}