import React from "react";

import img10 from "../../Assets/Images/img37.jpg";



import Fade from "react-reveal/Fade";

export default function Hrcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">        
          Transform your HR operations with our comprehensive human resources services! <br/> <br/>

          At <b>LITVIK</b>, we specialize in providing businesses with innovative and 
          effective solutions for all their HR needs. Our experienced HR 
          professionals will work with you to streamline your HR processes, improve
          employee engagement, and ensure legal compliance. Whether you're looking 
           to outsource your HR function or improve your existing operations, we have the 
           expertise and resources to help you succeed. <br/><br/>

          Contact us today to learn more about how we can help you build a better HR function for your business ! 
            
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
