import React, { useRef } from "react";
import "./Ctcomp.css";

import emailjs from "@emailjs/browser";

import img12 from "../../Assets/Images/img12.jpg";
import img13 from "../../Assets/Images/submit.png";
import Fade from "react-reveal/Fade";

export default function Ctcomp() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
//    emailjs.sendForm("service_lx0w3qx","template_u4ewknm",form.current,"FHDTX4rvytSBMhRNF")
      emailjs.sendForm('service_cbxofis','template_y4wn83a', form.current, 'qZpFZWUlO2F6Xw7TX')
      .then(
        (result) => {
          console.log(result.text);
          alert("We will contact you soon");
        },
        (error) => {
          console.log(error.text);

          alert("Please Try Again!!");
        }
      );
  };
  return (
    <div>
      <div className="cctitle"> Contact us</div>
      <div className="cct1">
        <Fade>
          <div className="cct11">
            <img className="cimg" height="500" src={img12} />
            <br />
            <br />
          </div>
          <div className="cct12">
            <div>
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="f_name"
                  class="question"
                  id="nme"
                  required
                  placeholder="Name"
                  autocomplete="off"
                />
                <input
                  type="email"
                  name="f_mail"
                  class="question"
                  id="nme"
                  required
                  placeholder="Email"
                  autocomplete="off"
                />
                <input
                  type="number"
                  name="f_phone"
                  class="question"
                  id="nme"
                  required
                  placeholder="Phone"
                  autocomplete="off"
                />

                <label for="nme">
                  <span>Your Message</span>
                </label>
                <textarea
                  name="f_msg"
                  rows="2"
                  class="question"
                  id="msg"
                  required
                  autocomplete="off"
                ></textarea>
                
                <center><input className="csub" type="submit" value="Submit"/></center>
              </form>
              
            </div>
          </div>
        </Fade>
      </div>
      <br />
    </div>
  );
}
