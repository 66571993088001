import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";

import Mdevcomp from"../../Components/Servicecomp/Mdevcomp";

export default function Mobiledev() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="MOBILE APP DEVELOPMENT"/>
     <div className="ctcmp1" >
      <Zoom>
        <Mdevcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
