
import React from "react";

import img11 from "../../Assets/Images/img74.jpg";

import Fade from "react-reveal/Fade";

export default function Weighcomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">
          <b>Litvik WMS</b> is a powerful tool to manage your daily business.
           The weighbridge is an important data source in the business process.
            The system presents a unique opportunity to extract and manage 
            data from weighbridges. It allows for easy generation of 
            weighment slips for delivery challans, which are then updated 
            automatically in the delivery challan itself. This ensures 
            that the data is accurately documented over time.<br/><br/>

          A weighbridge management system is an easy way to keep 
          your weighbridge running efficiently, optimized, and compliant.
           We provide you with real-time data extraction from
            weighbridges, automatic updates, and more.<br/><br/>

          The weighbridge management system will provide you with 
          a dashboard in which you can view the data of your weighbridges
           and generate reports using the given data extraction parameters.
            <br />
            <br />
          </div>
          
        
          <div className="act12">
            
            <img className="d-block w-200" height="300" src={img11} />
            </div>
          
        
      
          <div className="act24">
              <table>
              <tr>
                <td >
                  
                  <ul>
                    <br/><li>Data extraction from wighbridge</li>
                    <li>Weighment slip generation</li>
                    <li>Auto updation in DC's</li>      
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          
        </Fade>
        
      </div>
    </div>
    
  );
}
