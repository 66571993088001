import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";



import Vascomp from"../../Components/Servicecomp/Vascomp";

export default function Va() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="VIRTUAL ASSISTANCE"/>
     <div className="ctcmp1" >
      <Zoom>
        <Vascomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
