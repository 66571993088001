import React from "react";

import img10 from "../../Assets/Images/img51.jpg";



import Fade from "react-reveal/Fade";

export default function Hncomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">       
          Streamline your IT infrastructure with our comprehensive hardware and networking solutions!<br/><br/> 

          At <b>LITVIK</b>, we specialize in providing businesses with high-performance 
          hardware and networking systems that meet their specific needs. Our 
           seasoned engineers will collaborate with you to design, implement, and 
          maintain a customized solution that perfectly aligns your business  objectives.
           Whether you're looking to upgrade your existing systems, build a new network, 
           or migrate to the cloud, we have the expertise and resources to drive your success.<br/><br/>   

            Get in touch with us today to discuss how we can enhance your technology 
            infrastructure for optimal performance ! <br/> 
            
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
