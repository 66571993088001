import React from "react";


import img12 from "../../Assets/Images/img77.jpg";

import Fade from "react-reveal/Fade";

export default function Qccomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">
          <b>Litvik QMS</b> consists of those processes, resources, policies,
            materials and activities that influence your product and service
            quality. It is important that you define, document, implement,
            maintain, and continuously improve your QMS. Documentation of your
            QMS should not be the goal, but instead be a means of achieving your
            goal.<br/><br/>
            Every ready mixed concrete company has a Quality Management System
            (QMS) in place. The System may be relatively informal with minimal
            documentation or quite elaborate with a comprehensive Quality Manual
            (QM). The desired level of documentation for your company is
            dependent upon the size and complexity of your organization and the
            competence of your personnel.
            
          </div>

          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img12}
            />
          </div>
          
          <div className="act22">
           
                       
            <table>
              <tr>
                <td>
                  {" "}
                  <br/>
                  <ul>
                    <li>Generating Mix Designs</li>
                    <li>Testing data info</li>
                    <li>Trail mix Information</li>
                    <li>Quality inspection data</li>
                    <li>Automated Quality assurance</li>
                  </ul>{" "}
                </td>
              </tr>
            </table>
          </div>
        </Fade>
      </div>
    </div>
  );
}
