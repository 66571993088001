import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp4 from "../../Components/Pagecomp4/Pagecomp4";
import Erpcomp from "../../Components/Productcomp/Erpcomp";

export default function Rmcerp() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp4 name="ReadyMix Concrete"/>
     <div className="ctcmp1" >
      <Zoom>
      <Erpcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
