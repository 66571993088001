import React from "react";

import img10 from "../../Assets/Images/img54.jpg";


import Fade from "react-reveal/Fade";

export default function Buscomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">     
          Streamline your business processes and boost your bottom line with our outsourcing services!<br/>  <br/>

          At <b>LITVIK</b>, we specialize in providing cost-effective and efficient solutions that 
           help businesses like yours save time and money. Our experienced professionals
            will work with you to understand your specific needs and develop a customized plan to 
            optimize your business processes.<br/> <br/> 

            We are here to outsource<br/>  
           
            <li>Administrative tasks</li>
            <li>Customer service</li> 
            <li>Any other business function</li>  <br/><br/>
            
            Contact us today to learn more about how we can help your business thrive through our expertise and resources ! 
            
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
