import React from "react";

import img10 from "../../Assets/Images/img17.jpg";

import img11 from "../../Assets/Images/img18.jpg";

import Fade from "react-reveal/Fade";

export default function Erpcomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">
          <strong>Litvik RMC</strong> has come up with a Solution for the Ready Mix Concrete Industries.
           It provides a detailed analysis report that helps to make the future
            decision for Admin, Operation, Quality, Accounts, Inventory, 
            Transport and Human Resources. LitVik RMC Software for especially
             to small & mid-sized Ready Mix Concrete Industries 
             that integrates all the functions (Sales & Marketing, Purchase, 
             Accounting, Inventory, Production, Quality).<br/><br/>
            It is an absolute cloud- based solutions. LitVik RMC
             Software for especially small & mid-sized Ready Mix Concrete
              Industries that integrates all the functions.<br/><br/>
          </div>
          
        
          <div className="act12">
            
            <img className="d-block w-200" height="300" src={img11} />
            </div>
          
        
      
          <div className="act24">
              <table>
              <tr>
                <td >
                  
                  <ul>
                    <li>Interactive Dashboard</li>
                    <li>Easy Administration</li>
                    <li>Quick day to day operations</li>
                    <li>Multiple plant support</li>
                    <li>Integration of all Department</li>
                    <li>Quality Contorl</li>
                    <li>Sales & Marketing</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Purchages & Inventory</li>
                    <li>Accounting & Taxation</li>
                    <li>Payroll</li>
                    <li>Fleet Management,Maintenance & Tracking</li>
                    <li>Flexible Reports & Analysis</li>
                    <li>Weighbridge Management</li>
                    <li>Notification & Warning Alerts</li>
                    
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          
        </Fade>
        
      </div>
    </div>
    
  );
}
