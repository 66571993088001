import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";
import Pagecomp from "../../Components/Pagecomp/Pagecomp";
import Ctcomp from "../../Components/Ctcomp/Ctcomp";
import './Contactus.css'

export default function Contactus() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp name="Contact Us"/>
     <div className="ctcmp1" >
      <Zoom>
      <Ctcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
