import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";

import Acccomp from "../../Components/Productcomp/Acccomp";

export default function Rmcacc() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="Accounting Management System"/>
     <div className="ctcmp1" >
      <Zoom>
     <Acccomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
