import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";

import Footer from "../../Components/Footer/Footer";
import Abtcomp from "../../Components/Abtcomp/Abtcomp";
import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";
import "./Aboutus.css";

export default function Aboutus() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name ="About us"/>
      
      <div className="abtcmp1" >
        <Abtcomp/>
      </div>
    
      <Footer/>
    </div>
  );
}
