import React from "react";
import "./Slider.css";
import img4 from "../../Assets/Images/img23.jpg";

import img5 from "../../Assets/Images/img5.jpg";

import img6 from "../../Assets/Images/img72i.jpg";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

export default function Slider() {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleClick = async (event) => {
    console.log("start");

    await sleep(5000);

    console.log("end");
  };
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          height="600"
          className="d-block w-100"
          src={img4}
          alt="First slide"
        />
        <Carousel.Caption>
       <h3><span style={{color:'White'}}> “Use the best possible materials, and reveal the quality of those materials
             and the craftsmanship of their assembly.” – Karl Friedrich Schinkel</span></h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          height="600"
          src={img5}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>
          <span style={{color:'white'}}>A great building must begin with the immeasurable, must go through
            measurable means when it is being designed, and in the end must be
            unmeasured.” – Louis Kahn</span>
          </h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          height="600"
          src={img6}
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>
          <span style={{color:'white'}}>"You can dream, create, design, and build the most wonderful place
            in the world. But it requires people to make the dream a reality.” –
            Walt Disney.</span>
          </h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
