import React from "react";

import Fade from 'react-reveal/Fade';
import './Pagecomp5.css';
import img1 from "../../Assets/Images/img14.jpg";
export default function Pagecomp5(props) {
  return (
    <div className="container4">
      <img  src={img1} className="img2" />

      <div className="centered">
        <div className="center2">
          <h1 class="home-title">
            <Fade left>
              <span className="spnt3"> &nbsp;{props.name}</span>
              {/* <span> Explore Our Recent Projects</span> */}
            </Fade>
          </h1>
        </div>
      </div>
    </div>
  );
}
