import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";
import Pagecomp4 from "../../Components/Pagecomp4/Pagecomp4";


import './Demo.css'
import Demcomp from "../../Components/Demcomp/Demcomp";

export default function Demo() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp4 name="Demo"/>
     <div className="ctcmp1" >
      <Zoom>
      <Demcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
