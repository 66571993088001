import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./MNavbar.css";
import img1 from "../../Assets/Images/litvik logo.png";
function MNavbar() {
  return (
    <>
      <Navbar expand="lg" className="color-nav" sticky="top">
        <Container>
          
          <Navbar.Brand className="color" href="/"><img 
          className="d-block w-800"
          height="280"
          src={img1}/>
      
          </Navbar.Brand>
          <Navbar.Toggle />

          <Navbar.Collapse className="color-br">
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link href="/" className="navtxt">Home</Nav.Link>
              <NavDropdown title="Services" className="navtxt" id="basic-nav-dropdown">
                <NavDropdown.Item href="/software-developement">Software Development</NavDropdown.Item>
                <NavDropdown.Item href="/mobile-developement">Mobile App Development</NavDropdown.Item>
                <NavDropdown.Item href="/website-developement">Website Development</NavDropdown.Item>
                <NavDropdown.Item href="/business-process">Business Process Outsourcing</NavDropdown.Item>
                <NavDropdown.Item href="/freelancing">Freelancing</NavDropdown.Item>
                <NavDropdown.Item href="/virtual-assistance">Virtual Assistance</NavDropdown.Item>
                <NavDropdown.Item href="/software-training">Software Training</NavDropdown.Item>
                <NavDropdown.Item href="/human-resource">Human Resource Agency</NavDropdown.Item>
                 <NavDropdown.Item href="/hardware-networking">Hardware And Networking </NavDropdown.Item>
                  <NavDropdown.Item href="/statuory-returns">All Statutory Returns</NavDropdown.Item>

              </NavDropdown>
              <NavDropdown title="Products" className="navtxt"  id="basic-nav-dropdown">
              <NavDropdown.Item href="/rmc-erp">ReadyMix Concrete ERP(RMC ERP)</NavDropdown.Item>
                <NavDropdown.Item href="/rmc-hrm">Human Resource Management System(HRMS)</NavDropdown.Item>
                <NavDropdown.Item href="/rmc-inventory">Inventory Management System(IMS)</NavDropdown.Item>
                <NavDropdown.Item href="/rmc-acc">Accounting Management System(AMS)</NavDropdown.Item>
                <NavDropdown.Item href="/rmc-qc">Quality Management System(QMS)</NavDropdown.Item>
                <NavDropdown.Item href="/rmc-fleet">Fleet Management System(FMS)</NavDropdown.Item>
                <NavDropdown.Item href="/rmc-weigh">Weighbridge Management System(WMS)</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/demo"className="navtxt">Demo</Nav.Link>{" "}
              <Nav.Link href="/aboutus"className="navtxt">About</Nav.Link>
              <Nav.Link href="/contactus"className="navtxt">Contact </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MNavbar;
