import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";


import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";
import Hrcomp from"../../Components/Servicecomp/Hrcomp";


export default function Humanres() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="HUMAN RESOURCE AGENCY"/>
     <div className="ctcmp1" >
      <Zoom>
        <Hrcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
