import React from "react";

import img10 from "../../Assets/Images/img56.jpg";



import Fade from "react-reveal/Fade";

export default function Strcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">        
          Empower your team with the skills they need to succeed with our software training services!  <br/><br/>

          At <b>LITVIK</b>, we offer customized training programs 
           tailored to meet your organization's unique needs. Our experienced 
           trainers will work with you to develop a curriculum that
            covers the software tools and technologies your team needs to master. 
            Whether you're looking to upskill your existing employees or train
             a new team, we have the expertise and resources to help you succeed. <br/> <br/>

          Contact us today to learn how we can help you build a more knowledgeable and productive team !<br/><br/> 

          Our Trainers can train your team on the following skillset <br/>
          <li>Excel </li>
          <li>Google Sheet </li>
          <li>SharePoint</li> 
          <li>Power BI </li>
          <li>Tableau </li>
          <li>Data Analytics </li>
          <li>Python </li>
          <li>JavaScript </li>
          <li>React    </li>        
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
