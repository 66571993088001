import React from "react";
import "./Abtcomp.css";

import img10 from "../../Assets/Images/img10.jpg";

import Fade from 'react-reveal/Fade';

export default function Abtcomp() {
  return (
    <div>

      <div className="act1">
        <Fade>
        <div className="act22">
          
         
        <strong>Litvik Software Labs Pvt. Ltd., branded as Litvik</strong>, is a
         software development firm that specialises in small and mid-sized ready-mix 
         concrete operations.The LitVik was established by a group of young and 
         enthusiastic people. The LitVik team has over 20 years of experience in 
         software development and the ready-mix concrete industry.We are committed 
         to providing cloud solutions to our customers.<br/><br/>

        We are pioneers in the development of RMC Software solutions.
        We started our first software solution as a desktop version in 2007.
         After serving dedicated customers with 100% satisfaction for the 
         past 14 years, we were inspired to offer our software 
         solutions to more customers in the RMC industry and started our 
         software division (the RMC wing) in August 2020.<br/><br/>

        Our software helps to integrate all the functions like multiple
         plant operations, sales and marketing, purchases, accounting, inventory,
          quality, and production. Minimal training, easy usage, affordable,
           on-demand customer support, and accurate data<br/><br/>

        All your operations are just a touch away<br/>
  
        </div>
        <div className="act12">
          <img
            className="d-block w-100"
            height="300"
            src={img10}
          />
        </div>
        </Fade>
      </div>
      </div>
  );
}
