import React from "react";

import img10 from "../../Assets/Images/img22.jpg";

import Fade from "react-reveal/Fade";

export default function fleetcomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">
          <b> Litvik FMS</b> is the key to the success and 
          growth in your company. Fleet management system is a cloud-based 
          solution that tracks all vehicle maintenance and ensures a smooth 
          operation at all times to reduce downtime and improve profit. <br/><br/>

          WithFleet Management Software, you can Track your fleet vehicle
           maintenance, usage and fuel consumption. It includes the ability to track
           transit mixers, fleet expenses, and all vehicle maintenance information 
           such as oil changes, tire rotations, and more. The cloud-based system allows
            for one central database where all data is stored on the cloud, so there is 
            no need for separate records book or paper logs.<br/><br/> 

          This system tracks all vehicle information and history and make available at
           the click of a button. An intuitive graphical interface makes it easy to manage 
            fleets while giving you access to the data you need to make the right decision when it matters most.
          </div>
          
        
          <div className="act12">
            
            <img className="d-block w-200" height="300" src={img10} />
            </div>
          
        
      
          <div className="act24">
              <table>
              <tr>
                <td >
                  <br/>
                  
                  <ul>
                    <li>Movement, Scheduling & Tracking</li>
                    <li>Maintenance scheduls & Alerts</li>
                    <li>Spare parts stock,purchases</li>
                  </ul>
                </td>
                <td>
                <br/>
                
                  <ul>
                    <li>Diesel usage</li>
                    <li>Performance evaluation</li>
                    <li>Costing</li>                    
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          
        </Fade>
        
      </div>
    </div>
    
  );
}
