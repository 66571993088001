import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Homepage from "./Pages/Homepage/Homepage";
import Contactus from "./Pages/Contactus/Contactus";
import MNavbar from "./Components/Navbar/MNavbar";
import Aboutus from "./Pages/Aboutus/Aboutus";
import Demo from "./Pages/Demo/Demo";
import Rmchrm from "./Pages/Products/Rmchrm";
import Rmcbill from "./Pages/Products/Rmcweigh";
import Rmcqc from "./Pages/Products/Rmcqc";
import Rmcfleet from "./Pages/Products/Rmcfleet";
import Rmcinv from "./Pages/Products/Rmcinv";
import Rmcerp from "./Pages/Products/Rmcerp";

import Allstat from "./Pages/Services/Allstat";

import Business from "./Pages/Services/Business";
import Freelancing from "./Pages/Services/Freelancing";
import Hardwarenet from "./Pages/Services/Hardwarenet";
import Humanres from "./Pages/Services/Humanres";
import Mobiledev from "./Pages/Services/Mobiledev";
import Softwaredev from "./Pages/Services/Softwaredev";
import St from "./Pages/Services/St";
import Va from "./Pages/Services/Va";

import Websitedev from "./Pages/Services/websitedev";
import Rmcweigh from "./Pages/Products/Rmcweigh";
import Rmcacc from "./Pages/Products/Rmcacc";





function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} exact />
          <Route path="/aboutus" element={<Aboutus />} exact />
          <Route path="/contactus" element={<Contactus />} exact />
          <Route path="/demo" element={<Demo />} exact />
          <Route path="/rmc-hrm" element={<Rmchrm />} exact />
          <Route path="/rmc-qc" element={<Rmcqc />} exact />
          <Route path="/rmc-fleet" element={<Rmcfleet />} exact />
          <Route path="/rmc-acc" element={<Rmcacc />} exact />
          <Route path="/rmc-weigh" element={<Rmcweigh />} exact />
          <Route path="/rmc-erp" element={<Rmcerp />} exact />
          <Route path="/rmc-inventory" element={<Rmcinv />} exact />
          <Route path="/statuory-returns" element={<Allstat />} exact />
          <Route path="/business-process" element={<Business />} exact />
          <Route path="/freelancing" element={<Freelancing />} exact />
          <Route path="/hardware-networking" element={<Hardwarenet />} exact />
          <Route path="/human-resource" element={<Humanres />} exact />
          <Route path="/mobile-developement" element={<Mobiledev />} exact />
          <Route path="/software-developement" element={<Softwaredev />} exact />
          <Route path="/software-training" element={<St />} exact />
          <Route path="/virtual-assistance" element={<Va />} exact />
          <Route path="/website-developement" element={<Websitedev />} exact />

        </Routes>
      </Router>
    </div>
  );
}

export default App;