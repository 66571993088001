import React from "react";

import img10 from "../../Assets/Images/img50.jpg";



import Fade from "react-reveal/Fade";

export default function FLcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">       
          Get access to top-quality freelancing talent with our software development services!<br/> <br/> 

          At <b>LITVIK</b>, we connect businesses with the best freelancers in the industry, 
          providing you with the talent you need to get your projects done on time and 
          within budget.  Whether you're looking for a one-time project or a long-term
          partnership, we have the expertise and flexibility to help you achieve your goals.<br/><br/>  

          Our team of experienced project managers will work with you to understand 
          your specific needs and match you with the right freelancer for the job. 
          Whether you're looking for short-term or long-term solutions, we have the
           resources and expertise to help you succeed.<br/><br/> 

          Contact us today to learn more about how we can help you get the freelancing
           talent you need to drive your business forward ! 
            <br />
            <br />
           
              <table>
                <tr>
                  <td>
                    <ul>
                <li>Excel, Excel VBA</li>
                <li>Python</li>
                <li>Power BI</li>
            </ul>
            </td>
            <td>
              <ul>
                <li>Sharepoint</li>
                <li>React Js</li>
                <li>Linux Administration</li>
              </ul>
            </td>
            </tr>
            </table>
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
