import React from "react";

import img10 from "../../Assets/Images/img20.jpg";

import Fade from "react-reveal/Fade";

export default function Invcomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">
          <b>Litvik IMS</b> is designed to help small and
            growing businesses effortlessly manage their inventory across
            multiple plants.
            <br />
            <br/>
            Even if you have multiple warehouses in different locations, you can
            manage orders and warehouse transfers of stock from a single system.
            This way you can control the movement of the items without consuming
            a lot of time.
            <br />
            <br/>
             Your crucial reports like sales and purchase history, and
            inventory valuation get automatically updated on a real-time basis.
            You can also set up email and field update workflows to eliminate
            some manual tasks.
            
          </div>
          
        
          <div className="act12">
            
            <img className="d-block w-200" height="300" src={img10} />
            </div>
          
        
      
          <div className="act24">
              <table>
              <tr>
                <td >
                  
                  <ul>
                    <li>Live Stock Details</li>
                    <li>Procurement Data</li>
                    <li>Vendor Details</li>
                    <li>Purchase indents,ordera generation</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Receipt note generation</li>
                    <li>Material Quality Information</li>
                    <li>Stock Reports and Alerts</li>
                    <li>Payment Details</li>         
                    
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          
        </Fade>
        
      </div>
    </div>
    
  );
}
