import React from "react";

import img10 from "../../Assets/Images/img17.jpg";

import img11 from "../../Assets/Images/img18.jpg";

import Fade from "react-reveal/Fade";

export default function Acccomp() {
  return (
    <div>
      <div className="act3">
        <Fade>
          <div className="act11">         
          <b>Litvik AMS</b> based accounts management system has been widely used in the industry.
           The system provides accurate and real-time visibility into your business
            finances and allows for effective budgeting, forecasting and planning.
             The system allows users to manage funds and operations effectively with
             simple, intuitive interfaces that deliver real-time reporting capabilities and analytics. <br/><br/>
          
            We are the best provider of Accounting Management Systems. Our products
            are highly secure and efficient in handling account management with the  best
            features. This management system  ensures that you have an accurate 
            and comprehensive view of your company's financial status. <br/><br/>
            
          </div>

          <div className="act12">
            <img
              className="d-block w-100"
              height="250"
              width="200"
              src={img10}
            />
          </div>
          <div className="act22">
          
            <table>
              <tr>
                <td>
                  {" "}
                  <ul>
                    
                    <li>Ledgers</li>
                    <li>Performance Analysis</li>
                    <li>Bills</li>
                    <li>Cash flow</li>
                  </ul>{" "}
                </td>
                <td>
                  <ul>
                    <li>P&L Accounts</li>
                    <li>Balance Sheet</li>
                    <li>Daily Reports</li>
                    <li>Alerts on outstandings</li>
                  </ul>{" "}
                </td>
              </tr>
            </table>
          </div>
        </Fade>
      </div>
    </div>
  );
}
