import React from "react";

import img10 from "../../Assets/Images/img34.jpg";



import Fade from "react-reveal/Fade";

export default function Vascomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">
        Unleash the power of virtual assistance for your projects!<br/> <br/>

          At <b>LITVIK</b> we offer top-notch virtual assistance services that help
           businesses like yours achieve more with less. Our team of experienced
            virtual assistants will work with you to provide support for a wide range
             of tasks, from administrative tasks to complex project management.<br/>  <br/> 

          Whether you're looking for a dedicated virtual assistant or a flexible
           solution for your business, we have the expertise and resources to help you succeed.<br/>  <br/>

          Contact us today to learn more about how we can help you boost your 
          productivity and take your projects to the next level ! 
            
            <br />
            <br />
            <li>RMC Processes</li>
            <li>Tax Processes</li>
            <li>HR Functions</li>
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
