import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";
import Sdevcomp from"../../Components/Servicecomp/Sdevcomp";

export default function Softwaredev() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="SOFTWARE DEVELOPMENT"/>
     <div className="ctcmp1" >
      <Zoom>
        <Sdevcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
