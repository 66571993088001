import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";


import Buscomp from"../../Components/Servicecomp/Buscomp";

export default function Business() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="BUSINESS PROCESS OUTSOURCING"/>
     <div className="ctcmp1" >
      <Zoom>
        <Buscomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}
