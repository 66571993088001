import React from "react";

import img10 from "../../Assets/Images/img52.jpg";


import Fade from "react-reveal/Fade";

export default function Wdevcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">
          Transform your online presence with our top-notch website design and development services!<br/><br/>  

          <b>LITVIK</b> specializes in creating custom websites that  look great and 
          deliver a seamless user experience. Our expert designers and 
          developers will work with you to bring your vision to life while incorporating
           the latest design trends and web technologies.<br/> <br/> 

          Whether you're looking to build a new website or revamp an existing one, 
          we have the skills and expertise to help you achieve your goals.<br/> <br/> 

          Contact us today to take your online presence to the next level ! 
            
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
