import React from "react";
import "./Component1.css";
import img1 from "../../Assets/Images/img8.jpg";

import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

export default function Component1() {
  return (
    <div className="C1clas">
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div className="card card0 border-0">
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1 bg-light pb-5">
                <Zoom>
                  {" "}
                  <div className="row px-5 justify-content-center mt-4 mb-5">
                 
                  <img className="image"  src={img1} />
                  </div>
                </Zoom>
                {/* <div className="row px-3 text-center justify-content-center">
                  <h4>Collect everything in one place</h4>
                  <small className="text-muted px-5 mx-1 mx-lg-5">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip.
                  </small>
                </div> */}
              </div>
            </div>
            <div className="col-lg">
              <br></br>
             <h4> <div className="row px-3 text-center justify-content-center">
                Welcome to RMC Digital Solutions</div></h4>
                  <large >
                    <br />
                    <div className="row px-3 text-left justify-content-center"></div>
                  Our ERP Software is fully customized to suit the needs of Ready Mix
                    Concrete Companies with the capability to manage multiple locations. 
                    Our cloud-based Database and Advanced Reporting tool has an easy-to-use
                     interface with plant locations. Cloud Hosting, 
                    there is no need to invest in costly hardware and infrastructure setup. 
                    Every plant needs only a Personal Computer and internet 
                    connection to hook into the LitVik. <br/><br/>
                    <div className="act13">
                    <table>
                      <tr >
                        <td>
                       {""}
                       <ul>
                      <li>Minimal Setup and Training</li> 
                      <li> Cloud based Database</li>  
                      <li> Advanced Reporting tools</li>  
                      <li> Less Investment</li>  
                      <li> All in one Integration</li>  
                      <li> Fully Automated</li>  
                      <li> Highly Secured</li>   
                      <li> On-Demand Customer support</li>  
                      </ul>{""}
                      </td>
                      </tr>
                      
                    </table>
                    </div>
                  </large>
                
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
