import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";


import Hrmcomp from "../../Components/Productcomp/Hrmcomp";

export default function Rmchrm() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="Human Resource Management System"/>
     <div className="ctcmp1" >
      <Zoom>
        <Hrmcomp/>
      </Zoom>
      </div>
      <Footer/>
    </div>
  );
}
