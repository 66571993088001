import React from "react";
import "./Footer.css";
import img2 from "../../Assets/Images/img78.png";
import img3 from "../../Assets/Images/img79.png";
import img4 from "../../Assets/Images/img80.png";
import img5 from "../../Assets/Images/img81.png";
import Jump from "react-reveal/Jump";

export default function Footer() {
  return (
    <div className="container-foot">
      <footer className="bg-black">
        <div className="container py-5">
        
          
          <div className="row py-3">
            <div className="col-lg-4 col-md-6 mb-lg-0">

             
            <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0877006326195!2d80.15844911495348!3d12.90208201989048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525ff128fb327b%3A0x7c7d5a35390f52b4!2sLITVIK%20SOFTWARE%20LABS%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1667703924874!5m2!1sen!2sin" width="350" height="230" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <ul className="list-inline mt-4">
                <li className="list-inline-item">
                  <a href="#" target="_blank" title="twitter">
                    <i className="fab  fa-2x fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" target="_blank" title="pinterest">
                    <i className="fab fa-2x fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            <Jump>
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                <h6 className="text-uppercase text-white font-weight-bold mb-4">Contact us</h6>
                <ul className="list-unstyled mb-0">
                  <li className="mb-2">
                  <a className="color1-br" href="tel:7305006621"><img src={img2}/>+91 7305006621</a>
                  </li>
                  <li className="mb-2">
                  <a className="color1-br" href="mailto:support@litvik.in"><img src={img3}/>support@litvik.in</a>
                  </li>
                  <li className="mb-2">
                  <a href="https://www.facebook.com/profile.php?id=100090117560718&mibextid=ZbWKwL" target="_blank" title="facebook"
                     className="color1-br"><img src={img5}/>Litvik Labs
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://www.instagram.com/litvik_labs/" target="_blank" title="instagram"
                    className="color1-br"><img src={img4}/>litvik_labs
                  </a>
                </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-10 mb-6 mb-lg-0">
                <h6 className="text-uppercase text-white  font-weight-bold mb-4">
                  Products
                </h6>
                <ul className="list-unstyled mb-0">
                <li className="mb-2">
                    <a href="/rmc-erp" className="text-white text-capital">
                      ReadyMix Concrete ERP(RMC ERP)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/rmc-hrm" className="text-white text-capital">
                      Human Resource Management System(HRMS)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/rmc-inventory" className="text-white text-capital">
                      Inventory Management System(IMS)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/rmc-weigh" className="text-white text-capital">
                      Weighbridge Management System(WBS)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/rmc-qc" className="text-white text-capital">
                      Quality Management System(QMS)               
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/rmc-fleet" className="text-white  text-capitale">
                      Fleet Management System(FMS)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/rmc-fleet" className="text-white  text-capital">
                      Accounting Management System(AMS)
                    </a>
                  </li>
                </ul>
              </div>
            </Jump>
          </div>
        </div>
  
        <hr className="p-0 m-0 b-0" />

        <div className="bg-light py-2">
          <div className="container text-center">
            <p className="text-muted text-white mb-0 py-2">&copy; 2023 Litvik</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
