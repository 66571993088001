import React from "react";

import img10 from "../../Assets/Images/img53.jpg";



import Fade from "react-reveal/Fade";

export default function Mdevcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">    

          <br/><br/>  
          Bring your ideas to life with our expert mobile app development services! <br/><br/>

          At <b>LITVIK</b>, we specialize in creating high-quality, user-friendly, 
          and robust mobile applications that meet your business needs and exceed 
          your expectations. Whether you're looking to launch a new app or improve 
          an existing one, our team of experienced developers and designers will
           work with you every step to ensure your app is a success.<br/> <br/>

          Contact us today to learn more and take your business to the next level with a cutting-edge mobile app! 
            
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="450"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
