import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Card.css";
import img1 from "../../Assets/Images/img3.jpg";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";




import img59 from "../../Assets/Images/img59.jpg";
import img60 from "../../Assets/Images/img60.jpg";
import img61 from "../../Assets/Images/img61.jpg";
import img62 from "../../Assets/Images/img62.jpg";
import img63 from "../../Assets/Images/img63.jpg";
import img64 from "../../Assets/Images/img64.jpg";
import img65 from "../../Assets/Images/img65.jpg";
import img66 from "../../Assets/Images/img66.jpg";
import img67 from "../../Assets/Images/img67.jpg";
import img68 from "../../Assets/Images/img68.jpg";

export default function Cards() {
  return (
    <div>
      <div className="contc-1">
        <h1 class="home-title">
          
        <Fade center>
        <span className="spnct">&nbsp;  Our Clients</span>
          
          </Fade>
        </h1>
      </div>
      
      <Zoom>
      <OwlCarousel className="owl-theme" loop margin={10} autoplay={true} nav>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img59} />
            <Card.Body>
              <Card.Title>VRMX Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img60} />
            <Card.Body>
              <Card.Title>Vasantham Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img61} />
            <Card.Body>
              <Card.Title>Velavan concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img62} />
            <Card.Body>
              <Card.Title>SRC Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img63} />
            <Card.Body>
              <Card.Title>ACE Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img64} />
            <Card.Body>
              <Card.Title>RCC Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img65} />
            <Card.Body>
              <Card.Title>New Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img66} />
            <Card.Body>
              <Card.Title>Dhanlakshmi Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img67} />
            <Card.Body>
              <Card.Title>Skandha Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
        <div class="item">
          <Card style={{ width: "20rem" }}>
            <Card.Img variant="top" className="imgcrd" src={img68} />
            <Card.Body>
              <Card.Title>Tejas Concrete</Card.Title>
             
              {/* <Button variant="primary">More..</Button> */}
            </Card.Body>
          </Card>
        </div>
      </OwlCarousel>
      
      </Zoom>
    </div>
  );
}
