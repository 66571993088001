import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";

import Pagecomp5 from "../../Components/Pagecomp5/Pagecomp5";

import Weighcomp from "../../Components/Productcomp/Weighcomp";

export default function Rmcqc() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
     <Pagecomp5 name="Weighbridge Management System"/>
     <div className="ctcmp1" >
      <Zoom>
      <Weighcomp/>
      </Zoom>
       
      </div>
      <Footer/>
    </div>
  );
}

