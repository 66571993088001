import React from "react";

import img10 from "../../Assets/Images/img40.png";



import Fade from "react-reveal/Fade";

export default function Sdevcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">
          Innovation in software development is a constant
           process that requires a high degree of creativity, agility, and 
           adaptability to the ever-changing business environment.<br/><br/> 

          LITVIK provides a full range of advanced technologies and digital
           solutions to meet your business needs. From innovative eCommerce 
           software to mobile apps, we're here to help you engage customers and 
           drive growth. We can provide you with the right solution, whether a
            web-based application, or an outsourced business application. <br/><br/>

          Our services : 

          <li>Development and maintenance of Desktop,Mobile and Server Applications</li>
          <li>Web design </li>
          <li>Content management systems </li> 
          <li>Online portals </li>
          <br/><br/>
          Get in touch with us for your Software solutions that are customised to your requirement!
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
