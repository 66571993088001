import React from "react";

import img10 from "../../Assets/Images/img33.jpg";



import Fade from "react-reveal/Fade";

export default function Allstatcomp() {
  return (
    <div>
      <div className="act4">
        <Fade>
          <div className="act11">       
          Minimize compliance risk with our specialized statutory services ! <br/> <br/>

          Our team at <b>LITVIK</b> offers businesses a comprehensive suite of services
           to fulfill their mandatory legal obligations. Our technical experts will 
           assess your requirements and provide tailored solutions that cater to your 
           unique business needs. We've covered everything from fulfilling reporting 
           requirements to managing payroll and tax-related responsibilities. 
           Trust us to keep you in line with the 
           relevant laws and regulations.<br/>  <br/> 

          Contact us today to schedule a consultation and take the first step toward streamlined statutory compliance ! 
            
            <br />
            <br />
          </div>
          <div className="act12">
            <img
              className="d-block w-100"
              height="300"
              width="200"
              src={img10}
            />
          </div>
        </Fade>
      </div>
      <br />
      {/* <div className="act2">
        <Fade>
          <div className="act21">
            <img className="d-block w-100" height="300" src={img11} />
            <br />
          </div>
          <div className="act22">
             It gives you seamless integration
            across business functions and tight control and visibility over your
            business which is critical to run your operations efficiently and
            stay ahead in the highly competitive business environment.
            <br />
          </div>
        </Fade>
      </div> */}
    </div>
  );
}
