import React from "react";
import MNavbar from "../../Components/Navbar/MNavbar";
import Slider from "../../Components/Slider/Slider";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Components/Footer/Footer";
import Component1 from "../../Components/Component1/Component1";
import Cards from "../../Components/Cards/Cards";

export default function Homepage() {
  return (
    <div>
      {/* <Topbar/> */}
      <MNavbar />
      <Zoom>
        <Slider />
      </Zoom>
      <Zoom>
       <Component1/>
      </Zoom>
      <br/>
      <br/>
      <Cards/>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer/>
    </div>
  );
}
